import { createGlobalStyle } from 'styled-components';
import PropaneSVGBackground from '../images/the-propane-home-bg-en.jpg';
import ArrowDown from '../images/icon-arrow-down.png';

const GlobalStyles = createGlobalStyle`
.modal-backdrop-custom.fade.modal-backdrop.show {
    background-color: rgba(255, 255, 255, 0.75) !important;
    opacity: 1 !important;
  }

.propaneHome{
	.st0{fill:#3A2417;}
	.st1{fill:#FFFFFF;}
	.st2{fill:#21A4DE;}
	.svgTextContainer{
		:hover{
			cursor: pointer;
			.st2{fill: #f7991c};
			}
	}
	svg{
		display: block;
		top: 0;
		left: 0;
		right: 0;
		width: 1300px;
		height: auto;
		margin: 0 auto;
		background: url(${PropaneSVGBackground}) no-repeat center left;
		background-size: 100% auto;
		:focus{
			outline: none;
		}
		@media  (max-width: 1830px){
			width: 1200px;
		}
		@media (max-width: 1439px){
			width: 1050px;
		}
		@media (max-width: 1300px){
			width: 900px;
		}
		@media (max-width: 1023px){
			width: 650px;
		}
		@media (max-width: 767px){
			width: 100%;
			position: static;
		}
	}
}

@media (max-width: 767px){
	.tooltip-furnace-boiler{
		transform: translate(-20%, -40%);
	}
	.tooltip-water-heater{
		transform: translate(5%,-30%);
	}
	.tooltip-bbq{
		transform: translate(-35%,-15%);	
	}
	.tooltip-generator{
		transform: translate(-30%, 25%);	
	}
	.tooltip-heat-pump{
		transform: translate(0%, -20%);
	}
	.tooltip-gas-range{
		transform: translate(-20%, -20%);
	}
}

.propaneHome-tooltip {
	/* width: 425px;
    padding: 40px;
	font-size: 16px;
    line-height: 22px; */
	width: 250px;
    font-size: 9px;
    line-height: 12px;
    padding: 20px;
    background: #00539d;
    border-radius: 6px;
	text-align: center;
	font-family: 'HelveticaNeue-Light', arial, sans-serif;
    margin-bottom: 0;
    color: #fff;
	@media (max-width: 767px){
		width: 425px;
    	font-size: 16px;
    	line-height: 22px;
	}
	@media (max-width: 575px){
		width: 425px;
    	font-size: 24px;
    	line-height: 26px;
	}
}

		
/*------------------------------
    Gen. GENERAL 
------------------------------*/
	
	* {
		box-sizing: border-box;		
		-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-text-size-adjust: none;
		}
	
	body {
		margin: 0;
		padding: 0;	
		font-family: 'HelveticaNeue-Light', arial, sans-serif;	
		font-size: 20px;
		line-height: 32px;
		color: rgba(0,0,0,0.6);
		width: 100vw;
		max-width:100%;
		@media screen and (min-width: 1921px) {
			width: 1920px;
			margin: 0 auto;
			}
		}
	
	img {
		border: 0;
		vertical-align: top;
		}	
	
	h1, h2, h3, h4, h5 {
		margin: 0;
		padding: 0;
		font-weight: normal;
		font-style: normal;
		}

	h1 {	
		
		}		
	h2 {	
		position: relative;		
		margin: 0 0 35px 0;		
		font-family: 'MrAlex', sans-serif;
		font-size: 45px;
		line-height: 50px;
		color: #00539d;
		}
	h3 {			
		margin: 0 0 35px 0;		
		font-family: 'HelveticaNeue-Light', sans-serif;
		font-size: 32px;
		line-height: 37px;
		color: #00a6e8;
		}
	h4 {			
		padding: 0 0 15px 0;
		margin: 0 0 35px 0;		
		font-family: 'HelveticaNeue-Light', sans-serif;
		font-size: 24px;
		line-height: 29px;
		color: rgba(0,0,0,0.6);
		border-bottom: 1px solid rgba(0,0,0,0.15);
		font-weight: normal;
		}

	hr {
		height: 1px;
		border: none;
		background: rgba(0,0,0,0.15);
		margin: 60px 0;
		clear: both;
		opacity: 1 !important;
		@media(max-width:1399px){
			margin:50px 0;
		}
		}
		
	p {		
		margin: 0 0 40px 0;
		padding: 0;
		@media (max-width: 1399px) {
			margin: 0 0 30px 0;
		}
		}
		p.small {		
			font-style: italic;
			font-size: 16px;
			line-height: 24px;
			}
		
	p.lessMargin,
	h2.lessMargin,
	h3.lessMargin,
	h4.lessMargin {			
		margin-bottom: 10px;
		}

	h2 strong {			
		font-family: 'MrAlex', sans-serif;
		font-weight: normal;
		font-style: normal;
		}
	h2 b {			
		font-family: 'MrAlex', sans-serif;
		font-weight: normal;
		font-style: normal;
		color: inherit;
		}	
	h3 strong,
	h4 strong {			
		font-family: 'HelveticaNeue-Light', sans-serif;
		font-weight: normal;
		font-style: normal;
		}	
	h3 b,
	h4 b {			
		font-family: 'HelveticaNeue-Light', sans-serif;
		font-weight: normal;
		font-style: normal;
		color: inherit;
		}

	b {
		font-family: 'HelveticaNeue-Bold', arial, sans-serif;
		font-weight: normal;
		font-style: normal;
		color: #00000099;
		}
		
	a:hover, a:active, a:focus, object { 
		outline: none; 
		}			
	a {
		text-decoration: none;
		color: #00a6e8;	
		transition: .25s ease-out;
		}
	a:hover {
		text-decoration: none;
		color: #f7991c;
		}
	
	blockquote {	
		width: 100%;	
		padding: 60px;
		margin: 0 0 60px 0;
		background: rgb(0,0,0);
		background: -moz-radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%);
		background: -webkit-radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%);
		background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
		border-radius: 20px;		
		}
		blockquote *:last-child {	
			margin-bottom: 0 !important;
			}
	.body.middle blockquote {	
		background: #fff;		
		}
			
	sup {
		font-size: 11px;
		line-height: 11px;
		}
	h2 sup {
		font-size: 27px;
		line-height: 27px;
		}
	h3 sup {
		font-size: 18px;
		line-height: 18px;
		}

	form, 
	input,
	textarea {
		margin: 0;
		padding: 0;
		outline: none;
		-border-radius:0; 
		}
		.itemClass.first {
  /* position: absolute; */
  left: 0;
  top: 0;
  text-align: left;
}

.itemClass.last {
  /* position: absolute; */
  right: 0;
  top: 0;
  /* text-align: right; */
}

.disabled a {
  color: rgba(0,0,0,0.6) !important;
  pointer-events: none;
}

.activeLinkClass {
  color: rgba(0,0,0,0.6) !important;
  pointer-events: none;
  border: 2px solid rgba(0,0,0,0.15);
}
.activeLinkClass:after {
  content: "(current)";
  padding: 2px !important;
  margin: 2px !important;
}
.linkClassLast {
  text-decoration: none;
  font-size: 15px;
  line-height: 22px;
  font-family: 'Gotham-Book', arial, sans-serif;
  letter-spacing: 0.02em;
  color: #9fb577;
}

.linkClassFirst {
  text-decoration: none;
  font-size: 15px;
  line-height: 22px;
  font-family: 'Gotham-Book', arial, sans-serif;
  letter-spacing: 0.02em;
  color: #9fb577;
}
.first {
  /* margin-right: 45%; */
}

.linkClass {
	margin-right: 10px;
	text-decoration: none;
    color: #00a6e8;
    transition: .25s ease-out;
	font-family: 'HelveticaNeue-Light', arial, sans-serif;
    font-size: 20px;
    line-height: 32px;
	@media  (max-width: 1399px)
	{
		font-size: 20px;
		line-height: 32px;
	}
	@media (max-width: 767px)
	{
		font-size: 16px;
		line-height: 24px;
	}
}
.highlightText {
	color: #b0272d;
    background-color: rgba(0,0,0,0.05) !important;
}
/* GENERAL RESETS */

.mktoForm {
  width: 100% !important;
}
.mktoAsterix {
  float: left !important;
  padding-left: 0 !important;
  padding-right: 5px !important;
  color: #f7991c !important;
}
.mktoOffset {
  display: none !important;
}
.mktoFormRow {
  margin-bottom: 15px !important;
}

/* COLUMN LAYOUT */

.mktoFormCol {
  width: 50% !important;
  padding-right: 20px !important;
}

/* RESI CONTACT FORM SPECIFIC - EN */

#mktoForm_6288.mktoForm > div:nth-child(5) .mktoFormCol,
#mktoForm_6288.mktoForm > div:nth-child(6) .mktoFormCol,
#mktoForm_6288.mktoForm > div:nth-child(7) .mktoFormCol,
#mktoForm_6288.mktoForm > div:nth-child(8) .mktoFormCol,
#mktoForm_6288.mktoForm > div:nth-child(9) .mktoFormCol {
  width: 100% !important;
}

/* RESI CONTACT FORM SPECIFIC - FR */

#mktoForm_6341.mktoForm > div:nth-child(5) .mktoFormCol,
#mktoForm_6341.mktoForm > div:nth-child(6) .mktoFormCol,
#mktoForm_6341.mktoForm > div:nth-child(7) .mktoFormCol,
#mktoForm_6341.mktoForm > div:nth-child(8) .mktoFormCol,
#mktoForm_6341.mktoForm > div:nth-child(9) .mktoFormCol {
  width: 100% !important;
}

/* COMM CONTACT FORM SPECIFIC - EN */

#mktoForm_6291.mktoForm > div:nth-child(2) .mktoFormCol,
#mktoForm_6291.mktoForm > div:nth-child(6) .mktoFormCol,
#mktoForm_6291.mktoForm > div:nth-child(7) .mktoFormCol,
#mktoForm_6291.mktoForm > div:nth-child(8) .mktoFormCol,
#mktoForm_6291.mktoForm > div:nth-child(9) .mktoFormCol,
#mktoForm_6291.mktoForm > div:nth-child(10) .mktoFormCol {
  width: 100% !important;
}

/* COMM CONTACT FORM SPECIFIC - FR */

#mktoForm_6342.mktoForm > div:nth-child(2) .mktoFormCol,
#mktoForm_6342.mktoForm > div:nth-child(6) .mktoFormCol,
#mktoForm_6342.mktoForm > div:nth-child(7) .mktoFormCol,
#mktoForm_6342.mktoForm > div:nth-child(8) .mktoFormCol,
#mktoForm_6342.mktoForm > div:nth-child(9) .mktoFormCol,
#mktoForm_6342.mktoForm > div:nth-child(10) .mktoFormCol {
  width: 100% !important;
}

.mktoFieldWrap {
  width: 100% !important;
}
.mktoHasWidth {
  width: 100% !important;
}

/* LABELS */

.mktoLabel {
  width: 100% !important;
  margin-bottom: 10px !important;
  font-family: 'HelveticaNeue-Light', arial, sans-serif !important;
  font-size: 20px !important;
  line-height: 25px !important;
  font-weight: normal !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
.mktoLabel strong {
  padding: 0 !important;
}
label.mktoLabel[for='ExpressConsent'] {
  font-size: 16px !important;
  line-height: 24px !important;
}
.mktoRequiredField .mktoLabel {
  font-family: 'HelveticaNeue-Light', arial, sans-serif !important;
}
.mktoRadioList label {
  font-family: 'HelveticaNeue-Light', arial, sans-serif !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
.mktoRadioList input {
  margin-top: 3px !important;
}

/* CONSENT */

.mktoForm label.mktoLabel[for='ExpressConsent'],
.mktoForm label.mktoLabel[for='ExpressConsent'] span,
.mktoForm label.mktoLabel[for='ExpressConsent'] a {
  font-family: 'HelveticaNeue-Light', arial, sans-serif !important;
  font-size: 13px !important;
  line-height: 18px !important;
}
.mktoForm label.mktoLabel[for='ExpressConsent'] a {
  padding: 0 !important;
}

/* FIELDS */

.mktoForm input[type='text'],
.mktoForm input[type='url'],
.mktoForm input[type='email'],
.mktoForm input[type='tel'],
.mktoForm input[type='number'],
.mktoForm input[type='date'],
.mktoForm select.mktoField,
.mktoForm textarea.mktoField {
  display: block !important;
  width: 100% !important;
  height: auto !important;
  padding: 15px !important;
  font-family: 'HelveticaNeue-Light', arial, sans-serif !important;
  font-size: 20px !important;
  line-height: 20px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  background: rgba(0, 0, 0, 0.05) !important;
  border: none !important;
  border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-left: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 0 !important;
  cursor: auto !important;
  box-shadow: none !important;
}
.mktoForm textarea.mktoField {
  height: 100px !important;
  line-height: 25px !important;
}
.mktoForm select.mktoField {
  padding: 17px 15px !important;
  background: rgba(0, 0, 0, 0.05) url(${ArrowDown}) no-repeat right 21px !important;
  background-size: auto 10px !important;
  -moz-appearance: none !important; /* Firefox */
  -webkit-appearance: none !important; /* Safari and Chrome */
  appearance: none !important;
  border-radius: 0 !important;
  background-position: 95% 50% !important;
}

/* SUBMIT BUTTON */

.mktoForm .mktoButtonWrap {
  width: 200px !important;
  margin: 0 !important;
}
.mktoForm .mktoButtonWrap.mktoInset .mktoButton,
.mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
  display: inline-block !important;
  position: relative !important;
  top: 0 !important;
  padding: 15px 35px !important;
  margin-right: 10px !important;
  font-family: 'MrAlex', sans-serif !important;
  font-size: 24px !important;
  line-height: 28px !important;
  color: #00a6e8 !important;
  border: 2px solid #00a6e8 !important;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0) !important;
  border-radius: 30px !important;
  background: #fff !important;
  cursor: pointer !important;
  text-shadow: none !important;
  webkit-transition: all 0.25s ease-out !important;
  -moz-transition: all 0.25s ease-out !important;
  -o-transition: all 0.25s ease-out !important;
  -ms-transition: all 0.25s ease-out !important;
  transition: all 0.25s ease-out !important;
}
.mktoForm .mktoButtonWrap.mktoInset .mktoButton:hover,
.mktoForm .mktoButtonWrap.mktoSimple .mktoButton:hover {
  top: -5px !important;
  color: #f7991c !important;
  border-color: #f7991c !important;
  box-shadow: 0 5px 0 0 #f7991c !important;
}

@media screen and (max-width: 1439px) {
}

@media screen and (max-width: 767px) {
  .mktoFormCol {
    width: 100% !important;
    padding-right: 0 !important;
  }
  .mktoLabel {
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

@media screen and (max-width: 480px) {
  .mktoForm {
    padding: 0 !important;
  }
}


`;

export default GlobalStyles;
