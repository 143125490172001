import * as React from 'react';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { graphql, navigate } from 'gatsby';
import ContentWithBody from '../components/contentWithBody/contentWithBody';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import BackToTopScroll from '../components/backToTopScroll/backToTopScroll';
import GlobalStyles from '../styles/globalStyles';
import { Body404 } from '../styles';
import { Helmet } from 'react-helmet';
import ogLogo from '../images/og-logo.jpg';

const NotFoundPage = ({ data, location }) => {
  const cookies = new Cookies();
  const languageFilteredData =
    data?.allContentfulUltramarCommercial?.edges?.find((edge) =>
      cookies.get('language')
        ? edge?.node?.node_locale === cookies.get('language')
        : edge?.node?.node_locale === 'en-US'
    );

  React.useEffect(() => {
    if (location?.href !== location?.href?.toLowerCase()) {
      window.location.href = location?.href?.toLowerCase();
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <title>Page Not Found | Ultramar</title>
        <meta name="robots" content="noindex" />
        <meta property="og:image" content={ogLogo}></meta>
        <meta property="twitter:image" content={ogLogo}></meta>
      </Helmet>
      <Header />
      <Body404>
        <ContentWithBody
          sectionData={languageFilteredData?.node?.sections.find(
            (section) => section?.contentDetails
          )}
        />
      </Body404>

      <Footer />
      <BackToTopScroll />
    </>
  );
};

export default NotFoundPage;
export const pageQuery = graphql`
  query NotFoundPageQuery {
    allContentfulUltramarCommercial(
      filter: { identifier: { type: { eq: "notFound" } } }
    ) {
      edges {
        node {
          node_locale
          title
          slug
          contentModels
          sections {
            ... on ContentfulContent {
              id
              title
              type
              header
              contentDetails {
                raw
              }
            }
          }
        }
      }
    }
  }
`;
